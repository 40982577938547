import React from 'react';
import { Heading, PropertyGroup } from '../../components';

import css from './ListingPage.module.css';

const SectionMultiEnumMaybe = props => {
  let { heading, options, selectedOptions = [], uniqueKey, publicData } = props;

  if (!heading || !options || !selectedOptions) {
    return null;
  }
  const otherLanguage = publicData?.other_language;
  const isLanguage = uniqueKey === 'languages';
  if (isLanguage && otherLanguage) {
    options = options?.map(option => {
      if (option?.key === 'other') {
        return { ...option, label: otherLanguage };
      }
      return option;
    });
  }
  const selectOptionsOnly = selectedOptions?.map(option => options?.find(k => k.key === option));
  return (
    <div className={css.sectionMultiEnum}>
      <Heading as="h2" rootClassName={css.sectionHeading}>
        {heading}
      </Heading>
      <PropertyGroup
        id="ListingPage.amenities"
        options={selectOptionsOnly}
        // options={options}
        selectedOptions={selectedOptions}
        twoColumns={options.length > 5}
      />
    </div>
  );
};

export default SectionMultiEnumMaybe;
